<template>
  <section>
    <div class="pageBradcrumbs">
      <div class="containerLarge">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/marine', 'marine')">Home</b-button>
            </li>
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/size-your-system', 'marine')">Size your system</b-button>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + require('../assets/img/banner/size-your-system.jpg') + ')',
        }"
      >
        <div class="containerLarge">
          <div class="pageTitle-banner text-center">
            <div class="pageTitle-content">
              <h1 class="mt-4">HSS • Separators</h1>
              <div class="divider"></div>
              <p class="lead">
                Size Your System
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="containerLarge">
      <div class="row mt-5">
        <div class="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
            <p class="lead text-center pb-lg-4">Answer the following questions to find the right product for your needs.</p>
            <form class="steps-widget">
              <ul class="steps-list">
                <li class="active current" id="step1">
                  <div class="label"><b-button class="btn" @click="getFocus('step1',1)"><i></i></b-button> 
                    <h4>Are you looking for lubricant oil or fuel separation?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <b-form-group>
                        <b-form-radio v-model="rootType" value="lubrificant">Lubricant</b-form-radio>
                        <b-form-radio v-model="rootType" value="fuel">Fuel</b-form-radio>
                      </b-form-group>
                    </div>
                    <div class="action" >
                        <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step2',2)">Next</b-button>
                    </div>
                  </div>
                </li>
                <li id="step2" v-show="conditionStep2">
                  <div class="label"><b-button class="btn" @click="getFocus('step2',2)"><i></i></b-button> 
                    <h4>Do you need a self-cleaning or manual separator?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <b-form-group>
                        <b-form-radio v-model="cleaning" value="self">Self-cleaning</b-form-radio>
                        <b-form-radio v-model="cleaning" value="manual">Manual cleaning</b-form-radio>
                      </b-form-group>
                    </div>
                    <div class="action" >
                        <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step3',3)">Next</b-button>
                    </div>
                  </div>
                </li>
                <li id="step3" v-show="conditionStep3">
                  <div class="label"><b-button class="btn" @click="getFocus('step3',3)"><i></i></b-button> 
                    <h4>What fuel do you employ?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <b-form-group>
                        <b-form-radio v-model="fuelType" value="hfo">HFO</b-form-radio>
                        <b-form-radio v-model="fuelType" value="go">GO</b-form-radio>
                        <b-form-radio v-model="fuelType" value="mdo">MDO</b-form-radio>
                      </b-form-group>
                    </div>
                    <div class="action" >
                        <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step4',4)">Next</b-button>
                    </div>
                  </div>
                </li>
                <li id="step4" v-show="conditionStep4">
                  <div class="label"><b-button class="btn" @click="getFocus('step4',4)"><i></i></b-button> 
                    <h4>Is a certified flow required?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                       <b-form-group>
                        <b-form-radio v-model="certified" value="yes">Yes</b-form-radio>
                        <b-form-radio v-model="certified" value="no">No</b-form-radio>
                      </b-form-group>
                    </div>
                    <div class="action">
                        <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step5',5)">Next</b-button>
                    </div> 
                  </div>
                </li>
                <li id="step5" v-show="conditionStep5">
                  <div class="label"><b-button class="btn" @click="getFocus('step5',5)"><i></i></b-button> 
                    <h4> Do you know the fuel consumption assigned?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <b-form-group>
                        <b-form-radio v-model="isConsumptionFuel" value="yes">Yes</b-form-radio>
                        <b-form-radio v-model="isConsumptionFuel" value="no">No</b-form-radio>
                      </b-form-group>
                    </div>
                    <div class="action">
                        <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step6',6)">Next</b-button>
                    </div>
                  </div>
                </li>
                <li id="step6" v-show="conditionStep6">
                  <div class="label"><b-button class="btn" @click="getFocus('step6',6)"><i></i></b-button> 
                    <h4>The fuel consumption is</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <div class="input-group mb-3">
                        <input v-model="fuelConsumption" id="capacity" type="text" class="col-6 col-sm-3 col-lg-2 form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                        <div class="input-group-append">
                            <span class="input-group-text" id="inputGroup-sizing-default">l/h</span>
                        </div>
                      </div>
                    </div>
                    <div class="action text-center mt-5">
                        <a class="btn btn-lg btn-turquoise linkClick" @click="getResult">Size Your System</a>
                      </div>
                  </div>
                </li>
                <li id="step7" v-show="conditionStep7">
                  <div class="label"><b-button class="btn" @click="getFocus('step7',7)"><i></i></b-button> 
                    <h4>What is the total power of the engines?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <div class="input-group mb-3">
                        <input v-model="fuelPower" id="capacity" type="text" class="col-6 col-sm-3 col-lg-2 form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                        <select v-model="powerUnit" class="custom-select col-md-2">
                          <option value="kw">kW</option>
                          <option value="hp">HP</option>
                        </select>
                      </div>
                    </div>
                    <div class="action text-center mt-5">
                        <a class="btn btn-lg btn-turquoise linkClick" @click="getResult">Size Your System</a>
                    </div>
                  </div>
                </li>
                <li id="step8" v-show="conditionStep8">
                  <div class="label"><b-button class="btn" @click="getFocus('step8',8)"><i></i></b-button> 
                    <!-- for lubrificantType -->
                    <h4>What fuel do you employ?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <b-form-group>
                        <b-form-radio v-model="lubrificantType" value="hfo">HFO</b-form-radio>
                        <b-form-radio v-model="lubrificantType" value="go">GO</b-form-radio>
                        <b-form-radio v-model="lubrificantType" value="mdo">MDO</b-form-radio>
                      </b-form-group>
                    </div>
                    <div class="action">
                        <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step9',9)">Next</b-button>
                    </div>
                  </div>
                </li>
                <li id="step9" v-show="conditionStep9">
                  <div class="label"><b-button class="btn" @click="getFocus('step9',9)"><i></i></b-button> 
                    <h4>Do you now the lubricant consumption assigned?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <b-form-group>
                        <b-form-radio v-model="isConsumptionLubrificant" value="yes">Yes</b-form-radio>
                        <b-form-radio v-model="isConsumptionLubrificant" value="no">No</b-form-radio>
                      </b-form-group>
                    </div>
                    <div class="action">
                        <b-button class="btn btn-next btn-turquoise linkClick" @click="getFocus('step10',10)">Next</b-button>
                    </div>
                  </div>                  
                </li> 
                <li id="step10" v-show="conditionStep10">
                  <div class="label"><b-button class="btn" @click="getFocus('step10',10)"><i></i></b-button> 
                    <h4>The lubricant consumption is</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <div class="input-group mb-3">
                        <input v-model="lubrificantConsumption" id="capacity" type="text" class="col-6 col-sm-3 col-lg-2 form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                        <div class="input-group-append">
                            <span class="input-group-text" id="inputGroup-sizing-default">l/h</span>
                        </div>
                      </div>
                    </div>
                    <div class="action text-center mt-5">
                        <a class="btn btn-lg btn-turquoise linkClick" @click="getResult">Size Your System</a>
                      </div>
                  </div>
                </li>
                <li id="step11" v-show="conditionStep11">
                  <div class="label"><b-button class="btn" @click="getFocus('step11',11)"><i></i></b-button> 
                    <h4>What is the total power of the engines?</h4>
                  </div>
                  <div class="desc">
                    <div class="wrap">
                      <div class="input-group mb-3">
                        <input v-model="lubrificantPower" id="capacity" type="text" class="col-6 col-sm-3 col-lg-2 form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default">
                        <select v-model="powerUnit" class="custom-select col-md-2">
                          <option value="kw">kW</option>
                          <option value="hp">HP</option>
                        </select>
                      </div>
                    </div>
                    <div class="action text-center mt-5">
                        <a class="btn btn-lg btn-turquoise linkClick" @click="getResult">Size Your System</a>
                      </div>
                  </div>
                </li>
              </ul>
            </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    step: 1,
    rootType: null,
    cleaning: null,
    fuelType: null,    
    certified: null,
    isConsumptionFuel: null,
    fuelConsumption: null,
    fuelPower: null,
    lubrificantType: null,
    isConsumptionLubrificant: null,    
    lubrificantConsumption: null,    
    lubrificantPower: null,
    powerUnit: 'kw',
    flag: false,
  }),

  computed: {
    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    },

    getCategoryName(){
      return this.$store.getters.getCategoryName; //this.$route.params.category_name;
    },

    getCategoryId(){
      return this.$store.getters.getCategoryId; //this.$route.params.category_id;
    },

    conditionStep2(){
      return this.rootType !== null;
    },

    conditionStep3(){
      return this.cleaning !== null && this.rootType === 'fuel';
    },

    conditionStep4(){
      return this.rootType === 'fuel' && this.cleaning === 'self' && this.fuelType === 'hfo';
    },

    conditionStep5(){
      return this.certified !== null && this.fuelType !== null && this.rootType === 'fuel';
    },

    conditionStep6(){
      return this.isConsumptionFuel === 'yes' && this.rootType === 'fuel';
    },

    conditionStep7(){
      return this.isConsumptionFuel === 'no' && this.rootType === 'fuel';
    },

    conditionStep8(){
      return this.cleaning !== null && this.rootType === 'lubrificant';
    },

    conditionStep9(){
      return this.lubrificantType !== null && this.rootType === 'lubrificant';
    },

    conditionStep10(){
      return this.isConsumptionLubrificant === 'yes' && this.rootType === 'lubrificant';
    },

    conditionStep11(){
      return this.isConsumptionLubrificant === 'no' && this.rootType === 'lubrificant';
    },

  },

  methods: {
    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },
    
    getFocus(_stepname, stepnumber){
      console.log('arriva', _stepname, stepnumber);
      this.resetOther();

      if (stepnumber === 3 && this.rootType === 'lubrificant'){
        this.getFocus('step8', 8);
      } else if (stepnumber === 4 && (this.cleaning !== 'self' || this.fuelType !== 'hfo')){
        this.certified = 'no';
        this.getFocus('step5', 5);
      } else if (stepnumber === 6 && this.rootType === 'fuel' && this.isConsumptionFuel === 'no'){
        this.fuelConsumption = null;
        this.getFocus('step7', 7);
      } else if (stepnumber === 7 && this.rootType === 'fuel' && this.isConsumptionFuel === 'yes'){
        this.fuelPower = null;
      } else if (stepnumber === 10 && this.rootType === 'lubrificant' && this.isConsumptionLubrificant === 'no'){
        this.lubrificantConsumption = null;
        this.getFocus('step11', 11);
      } else if (stepnumber === 11 && this.rootType === 'lubrificant' && this.isConsumptionLubrificant === 'yes'){
        this.lubrificantPower = null;
      } else {
        this.insideStep(_stepname, stepnumber);
      }
      
    },

    resetOther(){
      if (this.rootType === 'lubrificant'){
        this.certified = null;
        this.fuelType = null;
        this.isConsumptionFuel = null;
        this.fuelConsumption = null;
        this.fuelPower = null;
      } else {        
        this.lubrificantType = null;
        this.isConsumptionLubrificant = null;
        this.lubrificantConsumption = null;
        this.lubrificantPower = null;
      }
    },

    insideStep(_stepname, stepnumber){
      this.step = stepnumber;      
      this.resetSteps();
      const currentStep = document.getElementById(_stepname);
      currentStep.classList.add('active', 'current');
    },

    resetSteps(){
      const step1 = document.getElementById('step1');
      const step2 = document.getElementById('step2');
      const step3 = document.getElementById('step3');
      const step4 = document.getElementById('step4');
      const step5 = document.getElementById('step5');
      const step6 = document.getElementById('step6');
      const step7 = document.getElementById('step7');
      const step8 = document.getElementById('step8');
      const step9 = document.getElementById('step9');
      const step10 = document.getElementById('step10');
      const step11 = document.getElementById('step11');

      step1.classList.remove('active', 'current');
      step2.classList.remove('active', 'current');
      step3.classList.remove('active', 'current');
      step4.classList.remove('active', 'current');
      step5.classList.remove('active', 'current');
      step6.classList.remove('active', 'current');
      step7.classList.remove('active', 'current');
      step8.classList.remove('active', 'current');
      step9.classList.remove('active', 'current');
      step10.classList.remove('active', 'current');
      step11.classList.remove('active', 'current');

    },

    getResult(){
      console.log('catId', this.getCategoryId);
      console.log('rootType', this.rootType);
      console.log('cleaning', this.cleaning);      
      console.log('fuelType', this.fuelType);
      console.log('certified', this.certified);
      console.log('isConsumptionFuel', this.isConsumptionFuel);
      console.log('fuelConsumption', this.fuelConsumption);
      console.log('fuelPower', this.fuelPower);
      console.log('lubrificantType', this.lubrificantType);     
      console.log('isConsumptionLubrificant', this.isConsumptionLubrificant);      
      console.log('lubrificantConsumption', this.lubrificantConsumption);      
      console.log('lubrificantPower', this.lubrificantPower);
      console.log('powerUnit', this.powerUnit);
      
      this.$http.get('/api/v1/configuratore-hss', {
        params: { 
          catId: this.getCategoryId,
          rootType: this.rootType,
          cleaning: this.cleaning,
          fuelType: this.fuelType,
          certified: this.certified,
          isConsumptionFuel: this.isConsumptionFuel,
          fuelConsumption: this.fuelConsumption,
          fuelPower: this.fuelPower,
          lubrificantType: this.lubrificantType,
          isConsumptionLubrificant: this.isConsumptionLubrificant,
          lubrificantConsumption: this.lubrificantConsumption,
          lubrificantPower: this.lubrificantPower,
          powerUnit: this.powerUnit          
        }
      })
        .then((resp) => {
          console.log('[Result configurator]', this.getCategoryName, resp);
          /* eslint-disable no-underscore-dangle */
          if (resp.data._id) {
            const usage = {};
            usage.productId = resp.data._id;
            usage.product = resp.data.name + ' ' + resp.data.size;
            usage.category = this.getCategoryName;
            this.$http.post('/api/datausage/save', usage)
              .then((ris) => {
                this.$store.commit('setCurrentGlobal', 'marine');
                this.$store.commit('setCurrentPath', '/product/' + resp.data._id);

                this.$router.push({
                  name: 'Product',
                  params: {
                    id: resp.data._id,
                    categoryId: this.getCategoryId
                  }
                });
              })
              .catch((err) => {
                console.log('Error on save datausage', err);
              });
            
          } else this.$router.push('/size-your-system/notfound');
        })
        .catch((err) => {
          console.log('Error on configurator', err);
        });
    }
  }
};
</script>
